export function isValidUrl(urlString: string) {
  try {
    // we want the error to throw if the URL is invalid
    // eslint-disable-next-line local/no-url-without-base
    new URL(urlString);
    return true;
  } catch (_error) {
    return false;
  }
}
